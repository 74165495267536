<template>
  <div>
    <Hero />
    <hr />
    <p>Our apologies. Your form submission did not go through.</p>
    <router-link to="/">
      <button>Return to home page.</button>
    </router-link>
  </div>
</template>

<script>
    import Hero from "../components/Hero";

    export default {
        components: {
            Hero,
    },
        name: 'notsent'
    }
</script>